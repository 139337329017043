import { createSlice } from '@reduxjs/toolkit'

const galleryDataslice = createSlice({
    name: 'galleryData',
    initialState: { gallery_data: null },
    reducers: {
        updateGalleryData: (state, { payload: { data } }) => {
            state.gallery_data = data
        },
        clearGalleryData: (state, { }) => {
            state.gallery_data = null
        },
    },
})

export const { updateGalleryData, clearGalleryData } = galleryDataslice.actions

export default galleryDataslice.reducer

