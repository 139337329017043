export default build =>
    build.query({
        tagTypes: ['allAgents'],
        keepUnusedDataFor: 1,
        query(payload) {
            //console.log("payload",payload)            
            return {
                url: `/exam/getAllAgents?page_number=${payload.page_number}&page_size=${payload.page_size}`,
                method: 'GET',
               // body: payload,
            };
           
        },
       providesTags: ['allAgents']
    })
