import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLoginMutation, useSignupMutation } from '../Services/modules/auth';
import { updateUserData } from '../Store/UserData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, useNavigate } from 'react-router-dom';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

function Signup() {
  const isLoggedin = useSelector(state => state.userData.user_data == null ? false : true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    username: "",
    password: ""
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const[isSumbmiting,setIsSubmiting]=useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [Signup, { data, isSuccess, isLoading, isError, error }] = useSignupMutation();

  useEffect(() => {
    if (isSuccess) {
      console.log("InSuccess", data);

      if (data.error === true) {
        toast.error(data.message);
        setIsSubmiting(false)

        return;
      }
      if (data.error === false) {
        toast.success(data.message);
        setTimeout(() => {
          navigate({
            pathname: "/login",
          });
        }, 1000);
      }
     // dispatch(updateUserData({ data: data }));
    }
    if (isError) {
      setIsSubmiting(false)

      toast.error("In errorSomething went wrong");
      console.log(error);

    }
  }, [isLoading]);

  function onSubmit() {
    setIsSubmiting(true)
    setTimeout(()=>{
    Signup(state);

    },1000)
  }
  const goToSignin=()=>{
    navigate("/login")
       }
  const validateForm = () => {
    const { email, password } = state;
    if (email !== '' && password !== '') {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
    validateForm();
  }
  const handleKeypress = e => {
    
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    isLoggedin ? <Navigate to="/dashboard" /> :
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="container">
          <ToastContainer/>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="white-box p-4">
                <h2 className="text-center">Signup</h2>
                <div className="form-group">
                  <label htmlFor="username" className="mb-2">Username</label> {/* Added margin-bottom */}
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter your username"
                    value={state.username}
                    name="username"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-12 position-relative">
                  <label htmlFor="password" className="mb-2">Password</label> {/* Added margin-bottom */}
                  <input
                   type={passwordVisible ? 'text' : 'password'}
                    className="form-control"
                    id="password"
                    name="password"
                    value={state.password}
                    onChange={handleChange}
                    onKeyDown={handleKeypress}

                    placeholder="Enter your password"
                  />
                   <span
                            className="position-absolute"
                            style={{
                              right: '10px',
                              top: '60%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? <RiEyeOffFill /> : <RiEyeFill />}
                          </span>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                  onClick={() => onSubmit()}
                  disabled={!isFormValid || isLoading || isSumbmiting} 
                >
                  {isLoading || isSumbmiting ? 'Submitting...' : 'Submit'} 
                </button>
                <a onClick={()=>goToSignin()} style={{cursor:'pointer'}}> 
              
              <div class="section-title" >
      <h4 style={{marginTop:'20px',fontSize:'20px'}}>
       <span style={{color:'#4c70ff'}}>Already have an account?</span>  
       <span style={{color:'#db5eff'}}> Signin</span>
        </h4>
        </div>
              </a> 
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Signup;