import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import Template from '../Components/Template';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningModal from '../Components/WarningModal';
import axios from 'axios';
import { Config } from '../Config';
import { useNavigate } from 'react-router-dom';
import { useDeleteEventPhotoMutation, useGetEventPhotosQuery } from '../Services/modules/PhotoGallery';


function PhotoGalleryImages() {
    const navigate=useNavigate()
 
  const is_event_data=useSelector(state => state.eventData.event_data?true:false);
  const event_data=useSelector(state => state.eventData.event_data);  
  const is_gallery_data=useSelector(state => state.galleryData.gallery_data?true:false);
  const gallery_data=useSelector(state => state.galleryData.gallery_data);  
  const userToken = useSelector(state => state.userData.user_data.token);
 
//   console.log("gallerydata in image upload",gallery_data)
  // console.log("eventData in image upload",event_data)
//   console.log("gallery_id",gallery_id)
//   console.log("event_id",event_id)

    const[gallery_id,setGalleryId]=useState(gallery_data._id)
    const[event_id,setEventId]=useState(event_data._id)
    const [gallery_details,setGalleryDetails]=useState()
    const [event_details,setEventDetails]=useState()
    const[isLoadingPage,setIsLoading]=useState(true)
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const[product_images,setProductImages]=useState([])
    const[isDeleteModal,setDeleteModal]=useState(false)
    const[isDelete,setIsDelete]=useState(false) 
    const[image_id,setImageId]=useState()
    const[gallery_images,setGalleryImages]=useState([])
    const [thumbnailIndex, setThumbnailIndex] = useState(-1); 

useEffect(()=>{
    if(is_gallery_data && is_event_data)
    {
        // console.log("in useefctt eventdata",event_data)
        // console.log("in useefctt gallerydata",gallery_data)

        setGalleryDetails(gallery_data)
        setEventDetails(event_data)     
       // setEventId(event_data._id)

       // setGalleryId(gallery_data._id)
        setIsLoading(false)       


    }
    
    else
    {
        navigate("/photogalleryevent")
    }
},[])

const{
    data,
    isSuccess,
    isLoading,
    error,
    refetch
  }=useGetEventPhotosQuery({year_id:gallery_id,program_id:event_id})

  const [DeleteEventPhoto, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteIsLoading, isError:deleteIsError, error:deleteError }] = useDeleteEventPhotoMutation();

  useEffect(()=>{
    if(deleteSuccess)
    {
     setDeleteModal(false)
     setIsDelete(false)
      toast.success(deleteData.message)
    }
    if(deleteError)
    {
      setDeleteModal(false)
      toast.error(deleteData.message)
    }
    
  },[deleteData])
  useEffect(()=>{
if(isSuccess)
{
    setGalleryImages(data.data)
}
  },[data])
  useEffect(() => {
    if(isDelete)
    {
      console.log("In delete useeffect")
      DeleteEventPhoto({year_id:gallery_id,event_id:event_id,image_id:image_id})

    }
  
  }, [isDelete]);
  useEffect(()=>{
    console.log("Unse effet called",image_id)
    console.log("useeffect",gallery_id)
  },[thumbnailIndex])
  const handleFileSelect = (event) => {
    const files = event.target.files;
    const selected = Array.from(files);
    setSelectedImages(selected);

    // Create image previews
    const previews = selected.map(file => URL.createObjectURL(file));
    setImagePreviews(previews);
};
const handleImageUpload=async()=>{
    console.log("handle image upload",selectedImages)
    if (selectedImages.length==0) {
        toast.error('Please select an image to upload.');
        return;
      } 
      else
      {
        console.log("selectedImages axios",selectedImages)
        const formData = new FormData();
        for (let i = 0; i < selectedImages.length; i++) {
            const file = selectedImages[i];
            if (file.size <= 25 * 1024 * 1024) {
                formData.append('event_photos', file);
            } else {
             return console.log(`Skipping file ${file.name} due to size limit exceeded`);
            }
          }
       // formData.append('product_photos', selectedImages);
        formData.append("gallery_id",gallery_id)
        formData.append("event_id",event_id)

        try{
            const response = await axios.post(`${Config.API_URL_LOCAL}photogallery/uploadEventPhotos`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${userToken}`,
                },
                onUploadProgress: (progressEvent) => {
                  const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                  setUploadProgress(progress);
                },
              });
              console.log("image upload response", response); // Log the response data
              // Check response status or data to determine success
              if (response.status === 200) {
                  // Handle success
                  toast.success(response.data.message);
                  setTimeout(()=>{
                    setUploadProgress(0);
                  },2000)
                  refetch()
                  setSelectedImages([])
                  setImagePreviews([])
              } else {
                  // Handle failure
                  toast.error('Failed to upload image.');
              }
        }
        catch (error) {
            console.log("image upload error",error)
            toast.error('Error uploading image.');
          }
      } 
}
const clearSeletedImages=()=>{
    setSelectedImages([])

    setImagePreviews([])
}
const deleteGalleryImage=(image_id)=>{
  console.log("Image id",image_id)
  setDeleteModal(true)
  setImageId(image_id)
}

const toggleThumbnail = (index,image_id) => {
  console.log("Index",index)
  console.log("ImageId",image_id)
  if (index === thumbnailIndex) {
      setThumbnailIndex(-1); // If already selected, deselect it
  } else {
      setThumbnailIndex(index);
      
  }
  setImageId(image_id)
};
  const PhotoGalleryImagesScreen=()=>{
    return(
        <>
  <
                WarningModal
                setShowWarningModal={setDeleteModal}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDelete}
                showModal={isDeleteModal}
              />
              <ToastContainer/>
              <main id="main" class="main">
        {
        isLoadingPage?"Loading...":
        <div>
             <section id="services" class="services">
                <div className='container'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-body'>
                             <p>
                             Event Name:{event_details.program_name}
                                
                                </p> 
                                <p>
                             Year:{gallery_details.year}                                
                                </p>  
                               
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-body'>
                            <input type="file" accept="image/*"
                             multiple onChange={handleFileSelect} />
                              {imagePreviews.map((preview, index) => (
                                                        <img key={index} src={preview} alt={`Image ${index}`} style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} />
                                                    ))}
                                                   
                                                   
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                       
                        <div className='row'>
                        <div className='col-md-3'>
                       
                    <button 
                     className="btn btn-primary" 
                    onClick={handleImageUpload}>Upload</button>
                    </div>
                    <div className='col-md-3'>

                    <button 
                     className="btn btn-warning" 
                    onClick={clearSeletedImages}>Clear Selection</button>
                    </div>
                    
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                            {uploadProgress  > 0 && (
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {uploadProgress}%
              </div>
            </div>
          </div>
         
        </div>
         )}
                            </div>
                        </div>
                        <div className='row' style={{marginTop:'20px'}}>
                          <div className='col-md-12'>
                            <div className='card'>
                              <div className='card-body'>
                              <div className='row'>

                              {gallery_images?.map((data,index)=>{
                               // console.log("datassss",data)
                                 return(
                                    <div className='col-md-3'>
                                        <button 
                     className="btn btn-primary" 
                    onClick={()=>deleteGalleryImage(data._id)}>Delete</button>
                    
                                     <img
                
                 src={`${Config.API_URL_IMAGE}/PhotoGallery/${data.photo_path}`}

                  alt={`Image ${index}`}
                  style={{ width: '100%', cursor:'pointer',objectFit: 'cover' }}
                  //onClick={() => handleImageClick(image)}
                />
                </div>
                                  
                                 ) 
                              })}
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                   
                    </div>
                </div>
             </section>
        </div>
        }
        </main>
        </>
    )
  }
  return (
    <Template>{PhotoGalleryImagesScreen()}</Template>
  )
}

export default PhotoGalleryImages