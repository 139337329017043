import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'

function Sidebar() {
  const location = useLocation();
  const userData = useSelector((state) => state.userData.user_data);
let role=userData.role
   
  return (
   <>
   <aside id="sidebar" class="sidebar">

<ul class="sidebar-nav" id="sidebar-nav">
{/* <Link 
    to='/dashboard'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link " style={location.pathname === '/dashboard' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}}>
      <i class="bi bi-grid"></i>
      <span>Exam Forms</span>
    </a>
  </li>
  </Link> */}
  <Link 
    to='/dashboard'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link " style={location.pathname === '/dashboard' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}}>
      <i class="bi bi-grid"></i>
      <span>Exam Forms</span>
    </a>
  </li>
  </Link>
 {role!=="admin"? <Link 
    to='/reports'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link"  style={location.pathname === '/reports' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}} >
      <i class="bi bi-grid"></i>
      <span>Reports</span>
    </a>
  </li>
  </Link>:""}
  {
    role=="admin"?
    <Link 
    to='/reportsadmin'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link"  style={location.pathname === '/reports' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}} >
      <i class="bi bi-grid"></i>
      <span>Reports Admin</span>
    </a>
  </li>
 
  </Link>

  
  
  
  :""
  }
   {
    role=="admin"?
    <Link 
    to='/createagent'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link"  style={location.pathname === '/reports' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}} >
      <i class="bi bi-grid"></i>
      <span>Create Agent</span>
    </a>
  </li>
 
  </Link>

  
  
  
  :""
  }
  {/* <Link 
    to='/products'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link"  style={location.pathname === '/products' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}} >
      <i class="bi bi-grid"></i>
      <span>Products</span>
    </a>
  </li>
  </Link>
  <Link 
    to='/photogallery'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link"  style={location.pathname === '/photogallery' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}} >
      <i class="bi bi-grid"></i>
      <span>Photo Gallery</span>
    </a>
  </li>
  </Link>
  <Link 
    to='/reports'
    style={{textDecoration:'none'}}
    >
 
  </Link>
 */}

</ul>

</aside>
   </>
  )
}

export default Sidebar