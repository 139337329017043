export default build =>
    build.query({
        tagTypes: ['allExamForms'],
        keepUnusedDataFor: 1,
        query(payload) {
            console.log("payload in get exam forms",payload)            
            return {
                url: `/exam/getAllExamForms?page_number=${payload.page_number}&page_size=${payload.page_size}&agentId=${payload.agent_id}&`,
                method: 'GET',
               // body: payload,
            };
           
        },
       providesTags: ['allExamForms']
    })
