import React, { useEffect,useState } from 'react'
import { useSelector } from 'react-redux';
import Template from '../Components/Template';
import useDataTable from '../Hooks/useDataTable';
import { useGetAllExamFormsByAgentsAdminFilterMutation, useGetAllExamFormsByAgentsQuery, useGetAllExamFormsQuery } from '../Services/modules/exam';
import { MdOutlineNavigateNext,MdSkipNext,MdSkipPrevious,MdEdit, MdDelete   } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { Config } from '../Config';
import { useGetAllAgentsQuery } from '../Services/modules/agents';

function ReportAdmin() {
    const userData = useSelector((state) => state.userData.user_data);
  //  console.log("Userdata",userData)
    const [startIndex, setStartIndex] = useState("");
    const [endIndex, setEndIndex] = useState("");
    const {   
        handleFirstPage ,
        handlePageSizeChange,
        handleLastPage,
        sequenceStartIndex  ,
        page,
        rowsPerPage,
        totalRows,
        setTotalRows,
        setPage,
        setTotalPages,
        totalPages
        // Add other state variables and functions here
      }=useDataTable()
const[examForms,setExamForms]=useState([])
const [agents, setAgents] = useState([]);
const[agentId,setAgentId]=useState("")
const[isFilterApplied,setIsFilterApplied]=useState(false)
      const{data,
        isSuccess,
        isLoading,
        error,
        refetch
      }=useGetAllExamFormsQuery({agent_id:agentId, page_number:page,page_size:rowsPerPage})

      // const{data:dataAgentForms,
      //   isSuccess:isSuccessAgentForms,
      //   isLoading:isLoadingAgentForms,
      //   error:errorAgentForms,
      //   refetch:refetchAgentForms
      // }=useGetAllExamFormsByAgentsQuery({page_number:page,page_size:rowsPerPage})

  const [
    GetAllExamFormsByAgentAdminFilter, { 
    data:examFormsDataByAgent,
     isSuccess:examFormDataByAgentIsSuccess, 
     isLoading:examFormDataByAgentIsLoading, 
     isError:examFormDataByAgentIsError,
     error:examFormDataByAgentError }] = useGetAllExamFormsByAgentsAdminFilterMutation();

      const{data:dataAgents,
        isSuccess:isSuccessAgents,
        isLoading:isLoadingAgents,
        error:errorAgents,
        refetch:refetchAgents
      }=useGetAllAgentsQuery({page_number:page,page_size:1000})


      useEffect(()=>{
        if(isSuccess)
            {
                console.log("Exam form data",data)
                setTotalRows(data.totalRows)
                setExamForms(data.data)
            }
      },[data])
      useEffect(() => {
        // if(!isFilterApplied)
        // {
          console.log("In refetchhhh")
          refetch();

        //}
      }, [page]);
      useEffect(()=>{
       // console.log("Data agents",dataAgents)
        if(isSuccessAgents)
        {
         // console.log("Data agents",dataAgents.data)

          setAgents(dataAgents.data)
        }
      },[dataAgents])

      useEffect(()=>{
        console.log("exam form data by agents",examFormsDataByAgent)
        if(examFormDataByAgentIsSuccess)
        {
          console.log("Data agents",examFormsDataByAgent)
          setExamForms(examFormsDataByAgent.data)
         // setAgents(dataAgents.data)
        }
      },[examFormsDataByAgent])

      const exportToExcel = () => {
        fetch(`${Config.API_URL_LOCAL}exam/getAllExamFormsExcel`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add any other necessary headers here
            },
            // If your API requires a body, include it here
             body: JSON.stringify({ agentId: agentId }), // Example body
        })
        .then((response) => {
            console.log("response download", response);
            if (!response.ok) {
                alert("Already Exported")
                throw new Error('Network response was not ok', response);
            }
            const filename = response.headers.get('X-File-Name') || 'exported-data.xlsx';
            return response.blob().then(blob => ({ filename, blob }));
        })
        .then(({ filename, blob }) => {
            if (blob.size === 0) {
                throw new Error('Received an empty Excel file');
            }
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
            refetch()
        })
        .catch((error) => console.error('Error exporting to Excel:', error));
    };
    const exportToExcelAgain = () => {
        fetch(`${Config.API_URL_LOCAL}exam/getAllExamFormsExcelAgain`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add any other necessary headers here
            },
            // If your API requires a body, include it here
             body: JSON.stringify({ agentId: agentId }), // Example body
        })
        .then((response) => {
            console.log("response download", response);
            if (!response.ok) {
                alert("Already Exported")
                throw new Error('Network response was not ok', response);
            }
            const filename = response.headers.get('X-File-Name') || 'exported-data.xlsx';
            return response.blob().then(blob => ({ filename, blob }));
        })
        .then(({ filename, blob }) => {
            if (blob.size === 0) {
                throw new Error('Received an empty Excel file');
            }
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error('Error exporting to Excel:', error));
    };
    const exportToExcelRange = () => {
      fetch(`${Config.API_URL_LOCAL}exam/getAllExamFormsExcelRange`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              // Add any other necessary headers here
          },
      body: JSON.stringify({ startIndex,endIndex }), // Correctly format the body

          // If your API requires a body, include it here
          // body: JSON.stringify({ key: 'value' }), // Example body
      })
      .then((response) => {
          console.log("response download", response);
          if (!response.ok) {
              alert("Already Exported")
              throw new Error('Network response was not ok', response);
          }
          const filename = response.headers.get('X-File-Name') || 'exported-data.xlsx';
          return response.blob().then(blob => ({ filename, blob }));
      })
      .then(({ filename, blob }) => {
          if (blob.size === 0) {
              throw new Error('Received an empty Excel file');
          }
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error('Error exporting to Excel:', error));
  };
    const handleExportRange = () => {
      // Check if both start and end index are provided
      if (startIndex && endIndex) {
          // Perform export for the specified range
          console.log("Start Index:", startIndex);
          console.log("End Index:", endIndex);
          exportToExcelRange()
          // Add your export logic here
      } else {
          alert("Please enter both start and end index.");
      }
  };
  // const handleApplyAgentFilter=()=>{
  //   console.log("in handle apply agent filterrrrrr")
  //   setIsFilterApplied(true)
  //   GetAllExamFormsByAgentAdminFilter({
  //     agent_id:agentId,
  //     page_number: 0,
  //     page_size: rowsPerPage,
  //   })
  // }
  useEffect(() => {
   // console.log("use effect triggred",agentId)
   refetch()   
  }, [agentId]);


    const ReportAdminScreen=()=>{
        return(
            <>
             <main id="main" class="main">

             <div className='container'>
                <div className='card'>
                    <div className='card-body'>
                      <div className="row"  style={{marginBottom:"20px"}}>

                     
                    <div className='col-md-3'>
                                        <select
                                            value={agentId}
                                            className='form-control'
                                            onChange={(e) => setAgentId(e.target.value)}
                                        >
                                            <option value="">Select Agent</option>
                                            {agents?.map((agentData) => {
                                             //   console.log("agentData", agentData);
                                                return (
                                                    <option key={agentData._id} value={agentData._id}>{agentData.full_name}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {/* <div className='col-md-3'>
                                    <button 
                                    onClick={()=>handleApplyAgentFilter()}

                                    >Apply Filter</button>

                                    </div> */}
                                    </div>
                        <button onClick={()=>exportToExcel()}>Export To Excel</button>
                        <button onClick={()=>exportToExcelAgain()}>Export To Excel Again</button>
                        <div className="index-range">
                                <input
                                    type="number"
                                    placeholder="Start Index"
                                    value={startIndex}
                                    onChange={(e) => setStartIndex(e.target.value)}
                                />
                                <input
                                    type="number"
                                    placeholder="End Index"
                                    value={endIndex}
                                    onChange={(e) => setEndIndex(e.target.value)}
                                />
                                <button onClick={handleExportRange}>Export Range</button>
                                </div>
                               
                    <table className="bordered-table">
        <thead>
          <tr>
          <th>Exported Status</th>
            <th>Index</th>
            <th>Exam</th>
            <th>Student</th>
            <th>Standard</th>
            <th>Student Address</th>
            <th>School Address</th>
            <th>Enrollment Date</th>
            <th>Agent Name</th>



            {/* Add more column headers as needed */}
          </tr>
        </thead>
        <tbody>
          {examForms.map((item, index) => (
            <tr key={index}>
              {/* <td>
                <MdEdit onClick={()=>handleProductEdit(item)} 
                style={{cursor:'pointer'}}/> 
              <MdDelete onClick={()=>handleProductDelete(item)} style={{cursor:'pointer'}}/>
              </td> */}
               <td>
        {item.is_exported ? (
          <span style={{ color: 'green' }}>&#10004;</span> // Green tick for true
        ) : (
          <span style={{ color: 'red' }}>&#10008;</span> // Cross for false
        )}
      </td>
              <td>{ sequenceStartIndex + index}</td>
              <td>
              {/* <a onClick={()=>navigateToProductDetails(item)} style={{cursor:'pointer',color:'blue'}}> */}

                {item.exam_name+" | "+item.exam_fees+" Rs "+item.form_created_date+" "+item.form_created_time}
                {/* </a> */}
                </td>  
                <td>             
                {item.first_name+" "+item.middle_name+" "+item.last_name}              
                </td>  
                <td>             
                {item.exam_standard}              
                </td>    
                {/* <td>             
                {item.student_address+" | "+item.student_district+" | "+item.student_taluka+" | "+item.mobile_number}              
                </td>   */}
                 <td>             
                {item.student_district+" | "+item.student_taluka+" | "+item.mobile_number}              
                </td>
                <td>             
                {item.school_name+" | "+item.school_district+" | "+item.school_taluka+" | "+item.school_area}              
                </td> 
                <td>             
                {item.enrollment_date}              
                </td> 
                <td>             
                {item.main_agent_name}              
                </td>               
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => handleFirstPage()} disabled={page === 0}><MdSkipPrevious/></button> 
      <button onClick={() => setPage(page - 1)} disabled={page === 0}><GrFormPrevious/></button>
      <button onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}><MdOutlineNavigateNext /></button>
       <button onClick={() => handleLastPage()} disabled={page === totalPages - 1}><MdSkipNext/></button> 
      <div>
        <select value={rowsPerPage} onChange={handlePageSizeChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      
      </div>
                    </div>
                </div>
            </div>
            
             </main>
          
            </>
        )
    }
  return (
    <Template>{ReportAdminScreen()}</Template>
  )
}

export default ReportAdmin