import { api } from '../../api'

import login from './login'
import signup from './signup'


export const loginApi = api.injectEndpoints({
    endpoints: build => ({
        Login: login(build), 
        Signup:signup(build)      
    }),
    overrideExisting: true,
})
export const { useLoginMutation,useSignupMutation } = loginApi
//export const { useLoginMutation, useLogoutMutation, useSignUpMutation, useChangePasswordMutation, useVerifyOTPMutation, useCheckMobileMutation } = loginApi
