export default build =>
    build.mutation({
        query(payload) {
            return {
                url: `/exam/gettalukabydistrict`,
                method: 'POST',
                body: payload
            };
        }
    })
