import React from 'react';
import Template from '../Components/Template';
import { Link } from 'react-router-dom';

function ExamDashboard() {
    const examDashboardScreen = () => {
        return (
            <>
                <main id="main" className="main">
                    <div className="container">
                        <div className="row">
                       
         <div className="col-md-3 d-flex justify-content-center">
         <Link 
     to={{
        pathname: '/examform',
        search: '?exam=tse'  // Adding query parameter
    }}
    style={{textDecoration:'none'}}
    >
                                <div className="card exam-card">
                                    <div className="card-body d-flex align-items-center justify-content-center">
                                        <h5 className="card-title">गुणवत्ता शोध परीक्षा</h5>
                                    </div>
                                </div>
                                </Link>
                            </div>
    
                           
                            <div className="col-md-3 d-flex justify-content-center">
                            <Link 
     to={{
        pathname: '/examform',
        search: '?exam=shatabdi'  // Adding query parameter
    }}
    style={{textDecoration:'none'}}
    >
         <div className="card exam-card">
                                    <div className="card-body d-flex align-items-center justify-content-center">
                                        <h5 className="card-title">शताब्दी शिष्यवृत्ती परीक्षा</h5>
                                    </div>
                                </div>
    </Link>
                               
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    };
    
    return (
        <Template>{examDashboardScreen()}</Template>
    );
}

export default ExamDashboard;