export default build =>
    build.mutation({
        query(payload) {         
            return {
                url: '/product/updateProduct',
                method: 'POST',
                body: payload,
            };
        },
        invalidatesTags: ['allProducts'],       
    })
