import React from 'react'

function Footer() {
  return (
    <>
     <footer id="footer" class="footer mt-auto">
    <div class="copyright">
      &copy; Copyright <strong><span>Skilluphere</span></strong>. All Rights Reserved
    </div>
  
  </footer>
    </>
  )
}

export default Footer