export const Config = {
   
    // API_URL_LOCAL: 'http://localhost:8002/api/',
    // API_URL_IMAGE: 'http://localhost:8002',

   API_URL_LOCAL: 'https://backend.sanglishikshansanstha.com/api/',
 API_URL_IMAGE: 'https://backend.sanglishikshansanstha.com'
  
  
  }
  