export default build =>
    build.query({
        tagTypes: ['eventPhotos'],
        keepUnusedDataFor: 1,
        query(payload) {
           
            console.log("in get gallery photos",payload)
            return {
                url: `/photogallery/getPhotosByYearAndEvent?year_id=${payload.year_id}&program_id=${payload.program_id}`,           
                method: 'GET',
              //  body: payload,
               
            };
        },
        providesTags: ['eventPhotos'],
    })
