import { createSlice } from '@reduxjs/toolkit'
const eventDataslice = createSlice({
    name: 'eventData',
    initialState: { event_data: null },
    reducers: {
        updateEventDataState: (state, { payload: { data } }) => {
            state.event_data = data
        },
        clearEventDataState: (state, { }) => {
            state.event_data = null
        },
    },
})

export const { updateEventDataState, clearEventDataState } = eventDataslice.actions

export default eventDataslice.reducer
