import { api } from '../../api'
import addProduct from './addProduct'
import addProductComposition from './addProductComposition'
import deleteProduct from './deleteProduct'
import deleteProductComposition from './deleteProductComposition'
import deleteProductImage from './deleteProductImage'
import getAllProducts from './getAllProducts'
import getProductById from './getProductById'
import markThumbnailImage from './markThumbnailImage'
import updateProduct from './updateProduct'

export const productApi = api.injectEndpoints({
    endpoints: build => ({
        AddProduct: addProduct(build),
        UpdateProduct: updateProduct(build) ,
        GetAllProducts:getAllProducts(build),
        DeleteProduct:deleteProduct(build)   ,
        DeleteProductImage:deleteProductImage(build),      
        GetProductById:getProductById(build),
        MarkThumbnailImage:markThumbnailImage(build),
        AddProductComposition:addProductComposition(build),
        DeleteProductComposition:deleteProductComposition(build)
    }),
    overrideExisting: true,
})

export const { 
    useAddProductMutation,
    useUpdateProductMutation,
    useGetAllProductsQuery,
    useDeleteProductMutation,
    useDeleteProductImageMutation,
    useGetProductByIdQuery,
    useMarkThumbnailImageMutation,
    useAddProductCompositionMutation,
    useDeleteProductCompositionMutation
 } = productApi

