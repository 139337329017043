import { api } from '../../api'

import createAgent from './createAgent'
import deleteAgent from './deleteAgent'
import getAllAgents from './getAllAgents'
import updateAgent from './updateAgent'

export const agentsApi = api.injectEndpoints({
    endpoints: build => ({
     
      CreateAgent: createAgent(build),
      DeleteAgent: deleteAgent(build),
      UpdateAgent:updateAgent(build),
        GetAllAgents:getAllAgents(build),
       
       
    }),
    overrideExisting: true,
})

export const { 
    useCreateAgentMutation,
    useGetAllAgentsQuery,
    useUpdateAgentMutation,
   useDeleteAgentMutation,
  
    
 } = agentsApi

