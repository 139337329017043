export default build =>
    build.mutation({
        query(payload) {         
            return {
                url: '/photogallery/addEventName',
                method: 'POST',
                body: payload,
            };
        },
        invalidatesTags: ['eventByYear'],
       
    })
