import React,{useState,useEffect} from 'react'
import Template from '../Components/Template'
import { useDeleteExamFormMutation, useGetAllExamFormsByAgentsQuery, useGetDistrictQuery, useGetTalukaByDistrictMutation, useSaveExamFormMutation } from '../Services/modules/exam';
import { Config } from '../Config';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useDataTable from '../Hooks/useDataTable';
import { MdOutlineNavigateNext, MdSkipNext, MdSkipPrevious, MdEdit, MdDelete } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import WarningModal from '../Components/WarningModal';
import { useSelector } from 'react-redux';
import { useCreateAgentMutation, useDeleteAgentMutation, useGetAllAgentsQuery, useUpdateAgentMutation } from '../Services/modules/agents';

function CreateAgents() {
  const location = useLocation();
  const userData = useSelector((state) => state.userData.user_data);
//console.log("Usedata",userData)
  const [isSumbmiting,setIsSubmitting]=useState(false)
  const [isUpdate,setIsUpdate]=useState("")  
  const[agent_uid,setAgentUId]=useState("")
  const[agent_name,setAgentName]=useState("")
  const[agent_id,setAgentId]=useState("")
  const[agent_mobile_number,setAgentMobileNumber]=useState('')

 const[agents,setAllAgents]=useState([])

 const[isDelete,setIsDelete]=useState(false) 
    const[isDeleteModal,setDeleteModal]=useState(false)

  
  const {   
    handleFirstPage,
    handlePageSizeChange,
    handleLastPage,
    sequenceStartIndex,
    page,
    rowsPerPage,
    totalRows,
    setTotalRows,
    setPage,
    setTotalPages,
    totalPages
} = useDataTable();
  const { data, isSuccess, isLoading, error, refetch } = useGetAllAgentsQuery({
    page_number: page,
    page_size: rowsPerPage
   
});
useEffect(() => {
  if (isSuccess) {
    console.log("Agent data",data)
     // console.log("Exam form data by agents", data);
      setAllAgents(data.data);
      setTotalPages(data.total)
      setTotalRows(data.total)
  }
}, [data, isSuccess]);
  const [CreateAgent, { data:saveData, isSuccess:saveSuccess, isLoading:saveLoading, isError:saveIsError, error:saveError }] = useCreateAgentMutation();
  
  const [DeleteAgent, { 
          data:deleteData=[], 
          isSuccess:deleteIsSuccess, 
          isLoading:deleteIsLoading, 
          isError:deleteIsError, 
          error:deleteError }] = useDeleteAgentMutation();
          const [UpdateAgent, { 
            data:updateData=[], 
            isSuccess:updateIsSuccess, 
            isLoading:updateIsLoading, 
            isError:updateIsError, 
            error:updateError }] = useUpdateAgentMutation();
 
  const onSubmit=()=>{
    setIsSubmitting(true)
    if(!isUpdate)
    {
        CreateAgent(
        {
         // agent_uid,
          agent_name:agent_name,
          agent_id:agent_id,
          agent_mobile_number,       
      }
    )

    }
    if(isUpdate)
    {
      UpdateAgent(
        {
            agent_uid: agent_uid,
            agent_name:agent_name,
            agent_id:agent_id,
            mobile_number:agent_mobile_number,     
      }
      )

    }
  }
  const clearAgentForm=()=>{
    setAgentMobileNumber("")
    setAgentId('')
    setAgentName('') 
    setAgentUId('')
    setIsUpdate(false) 
    setIsSubmitting(false)
  }
  useEffect(()=>{
    if(saveSuccess)
      {
        setIsSubmitting(false)
        clearAgentForm()
        toast.success("Agent saved successfully")
      }
  },[saveData])

  useEffect(()=>{
    if(updateIsSuccess)
      {
        setIsSubmitting(false)
        clearAgentForm()
        toast.success("Agent updated successfully")
      }
  },[updateData])

  const handleDelete=(dataRow)=>{
    setDeleteModal(true)
    setAgentUId(dataRow._id)
  }
  useEffect(() => {
    if(isDelete)
    {
      DeleteAgent({agent_uid})
    }
  
  }, [isDelete]);
  useEffect(()=>{

    if(deleteIsSuccess)
    {
      console.log("deleteddataProduct",deleteData)

      if(!deleteData.error)
      {
        // setProducts(deleteData.data)
        // setTotalRows(deleteData.totalRows)
        toast.success(deleteData.message)
        setIsDelete(false)
        setDeleteModal(false)


//refetch()
        
      }
      else
      {
        toast.error(deleteData.message)
        setIsDelete(false)
        setDeleteModal(false)

      }
    
    }
      },[deleteData])

      useEffect(() => {
        refetch();
      }, [page]);

      const handleEdit=(record)=>{
        console.log("record in edit agent",record)
        setAgentUId(record._id)       
        setAgentName(record.full_name)
        setAgentId(record.agent_id)
        setAgentMobileNumber(record.mobile_number)       
        setIsUpdate(true)
       }
    const AgentScreen=()=>{
      return(
        <>
         <
                WarningModal
                setShowWarningModal={setDeleteModal}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDelete}
                showModal={isDeleteModal}
              />
        <ToastContainer/>
        <main id="main" class="main">
<div className='container'>
  <div className="row">
  <div className='col-md-4'>
    <div className='card'>
      <div className="card-body">
   
    
            

                                        <div className="form-group">
                  <label htmlFor="input1">Agent Name <span className="red-star">*</span></label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="agent_name"
                  value={agent_name}
                  onChange={(e) => setAgentName(e.target.value)}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input1">Mobile Number <span className="red-star">*</span></label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="agent_mobile_number"
                  value={agent_mobile_number}
                  onChange={(e) => setAgentMobileNumber(e.target.value)}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input1">Agent Id <span className="red-star">*</span></label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="agent_id"
                  value={agent_id}
                  onChange={(e) => setAgentId(e.target.value)}
                   />
                </div>
            
                <div className='form-group'>
                <button type="submit" 
                className="btn btn-primary"              
                onClick={()=>onSubmit()}
                >
                   {isSumbmiting ? 
                  'Submitting...' : 
                  isUpdate?'Update':
                  'Submit'} 
                 
                </button>
                <button type="submit" 
                className="btn btn-primary"              
                onClick={()=>clearAgentForm()}
                >
                  Cancel 
                 
                </button>

                </div>
      </div>
    </div>
  </div>
  <div className='col-md-8'>
    <div className='card'>
      <div className="card-body">
      <table className="bordered-table">
                                    <thead>
                                        <tr>
                                          <th>Action</th>
                                            <th>Index</th>
                                            <th>Agent Id</th>
                                            <th>Agent Name</th>
                                            <th>Mobile Number</th>
                                            <th>Password</th>
                                         
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agents.map((item, index) => (
                                            <tr key={index}>
                                              <td>
              
              <MdDelete onClick={()=>handleDelete(item)} style={{cursor:'pointer'}}/>
              <MdEdit onClick={()=>handleEdit(item)} style={{cursor:'pointer'}}/>

              </td>
                                                <td>{sequenceStartIndex + index}</td>
                                                <td>
                                                    {item.agent_id}
                                                </td>
                                                <td>
                                                    {item.full_name}
                                                </td>
                                                <td>
                                                    {item.mobile_number}
                                                </td>
                                                <td>
                                                    {item.password}
                                                </td>
                                               
                                          
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
      <button onClick={() => handleFirstPage()} disabled={page === 0}><MdSkipPrevious/></button> 

                                <button onClick={() => setPage(page - 1)} disabled={page === 0}><GrFormPrevious /></button>
                                <button onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}><MdOutlineNavigateNext /></button>
       <button onClick={() => handleLastPage()} disabled={page === totalPages - 1}><MdSkipNext/></button> 

                                <div>
                                    <select value={rowsPerPage} onChange={handlePageSizeChange}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
      </div>
    </div>
  </div>
  </div>

</div>
        </main>
        </>
      )
      
    }
  return (
    <Template>
        {AgentScreen()}
    </Template>
  )
}

export default CreateAgents