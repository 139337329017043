export default build =>
    build.mutation({
      
        query(payload) {   
            console.log("in delete agent")      
            return {
                url: '/exam/deleteAgent',
                method: 'POST',
                body: payload,
            };
        },
        invalidatesTags: ['allAgents']       
    })
