export default build =>
    build.mutation({
        query(payload) {    
            console.log("Payload in delete product image",payload)     
            return {
                url: `/product/deleteProductComposition`,
                method: 'POST',
                body: payload,
            };
        },
        invalidatesTags: ['productbyid'],
       
    })

