import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Template from '../Components/Template';
import useDataTable from '../Hooks/useDataTable';
import { useDeleteExamFormMutation, useGetAllExamFormsByAgentsMutation, useGetSubAgentsByAgentQuery,useGetAllExamFormsByAgentsFilterMutation, useGetTotalAmountAgentQuery, useGetSchoolsAgentWiseQuery } from '../Services/modules/exam';
import { MdOutlineNavigateNext, MdSkipNext, MdSkipPrevious, MdEdit, MdDelete } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import WarningModal from '../Components/WarningModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Config } from '../Config';

function Reports() {
    const userData = useSelector((state) => state.userData.user_data);
    console.log("Userdata", userData);
   
    let token=userData.token
    const {   
        handleFirstPage,
        handlePageSizeChange,
        handleLastPage,
        sequenceStartIndex,
        page,
        rowsPerPage,
        totalRows,
        setTotalRows,
        setPage,
        setTotalPages,
        totalPages
    } = useDataTable();

    const [examForms, setExamForms] = useState([]);
    const [subAgents, setSubagents] = useState([]);
    const [schoolsAgentWise, setSchoolsAgentWise] = useState([]);

    const[total_amount,setTotalAmount]=useState("")
    const [subAgentValue, setSubAgentValue] = useState("");
    const [schoolNameValue, setSchoolNameValue] = useState("");

    const [examNameValue, setExamNameValue] = useState("");
    const [examStandardValue, setExamStandardValue] = useState("");
    const[enrollmentDate,setEnrollmentDate]=useState("")
    
    const[form_id,setFormId]=useState("")
    const[isDelete,setIsDelete]=useState(false) 
       const[isDeleteModal,setDeleteModal]=useState(false)
    // const { data, isSuccess, isLoading, error, refetch } = useGetAllExamFormsByAgentsQuery({
    //     page_number: page,
    //     page_size: rowsPerPage,
    //     subAgentValue: subAgentValue,
    //     examNameValue: examNameValue,
    //     examStandardValue: examStandardValue
    // });
  const [GetAllExamFormsByAgent, { data, isSuccess, isLoading, isError, error }] = useGetAllExamFormsByAgentsFilterMutation();

   
  const { data: dataSchools, isSuccess: isSuccessSchools, isLoading: isLoadingSchools, error: errorSchools, refetch: refetchSchools } = useGetSchoolsAgentWiseQuery();

    const { data: dataSubAgents, isSuccess: isSuccessSubagents, isLoading: isLoadingSubAgents, error: errorSubAgents, refetch: refetchSubagents } = useGetSubAgentsByAgentQuery({ page_number: page, page_size: rowsPerPage });
    const { data: dataTotalAmount, isSuccess: isSuccessTotalAmount, isLoading: isLoadingTotalAmount, error: errorTotalAmount, refetch: refetchTotalAmount } = useGetTotalAmountAgentQuery({ page_number: page, page_size: rowsPerPage });

    const [DeleteExamForm, { 
        data:deleteData=[], 
        isSuccess:deleteIsSuccess, 
        isLoading:deleteIsLoading, 
        isError:deleteIsError, 
        error:deleteError }] = useDeleteExamFormMutation();
    useEffect(() => {
        if (isSuccess) {
            console.log("Exam form data by agents", data);
            setExamForms(data.data);
            setTotalRows(data.totalRows)
        }
    }, [data]);
    useEffect(() => {
        if (isSuccessTotalAmount) {
            console.log("Exam form data totalamount", dataTotalAmount);
            setTotalAmount(dataTotalAmount.totalAmount);
        }
    }, [dataTotalAmount, isSuccessTotalAmount]);


    useEffect(()=>{
        if(isSuccessSchools)
            {
                console.log("DataSchools",dataSchools)
                setSchoolsAgentWise(dataSchools.allSchools)
            }
    },[dataSchools])

    useEffect(() => {
        if (isSuccessSubagents) {
            console.log("data of subagents", dataSubAgents);
            setSubagents(dataSubAgents.data);
        }
    }, [dataSubAgents, isSuccessSubagents]);

    const applyFilter = () => {
       // refetch();
        GetAllExamFormsByAgent({
           // page_number: page,
           page_number: 0,
            page_size: rowsPerPage,
            subAgentValue: subAgentValue,
            exam_name: examNameValue,
            exam_standard: examStandardValue,
            school_name:schoolNameValue,
            fillupby_id:subAgentValue,
            enrollment_date:enrollmentDate
        })
    };

    const handleDelete=(dataRow)=>{
        setDeleteModal(true)
        setFormId(dataRow._id)
      }
      useEffect(() => {
        if(isDelete)
        {
          DeleteExamForm({form_id})
        }
      
      }, [isDelete]);
      useEffect(()=>{
    
        if(deleteIsSuccess)
        {
          console.log("deleteddataProduct",deleteData)
    
          if(!deleteData.error)
          {
            // setProducts(deleteData.data)
            // setTotalRows(deleteData.totalRows)
            toast.success(deleteData.message)
            setIsDelete(false)
            setDeleteModal(false)
    
    
    //refetch()
            
          }
          else
          {
            toast.error(deleteData.message)
            setIsDelete(false)
            setDeleteModal(false)
    
          }
        
        }
          },[deleteData])

          useEffect(() => {
            GetAllExamFormsByAgent(
                {
                    page_number: page,
                    page_size: rowsPerPage,
                    subAgentValue: subAgentValue,
                    exam_name: examNameValue,
                    exam_standard: examStandardValue,
                    school_name:schoolNameValue,
                    fillupby_id:subAgentValue,
                    enrollment_date:enrollmentDate
                }
            );
          }, [page]);

          const exportToExcel = () => {
            fetch(`${Config.API_URL_LOCAL}exam/getAllExamFormsExcelAgentFilter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,  // Add the token here
                    // Add any other necessary headers here
                },
                // If your API requires a body, include it here
                 body: JSON.stringify({ 
                    subAgentValue: subAgentValue,
                    exam_name: examNameValue,
                    exam_standard: examStandardValue,
                    school_name:schoolNameValue,
                    fillupby_id:subAgentValue,
                    enrollment_date:enrollmentDate
                  }), // Example body
            })
            .then((response) => {
                console.log("response download", response);
                if (!response.ok) {
                    alert("Already Exported")
                    throw new Error('Network response was not ok', response);
                }
                const filename = response.headers.get('X-File-Name') || 'exported-data.xlsx';
                return response.blob().then(blob => ({ filename, blob }));
            })
            .then(({ filename, blob }) => {
                if (blob.size === 0) {
                    throw new Error('Received an empty Excel file');
                }
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
                //refetch()
            })
            .catch((error) => console.error('Error exporting to Excel:', error));
        };

        const exportToPDF = () => {
            const endpoint = 'exam/getAllExamFormsPdfAgentFilter';
        
            fetch(`${Config.API_URL_LOCAL}${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,  // Add the token here
                },
                body: JSON.stringify({ 
                    subAgentValue: subAgentValue,
                    exam_name: examNameValue,
                    exam_standard: examStandardValue,
                    school_name: schoolNameValue,
                    fillupby_id: subAgentValue,
                    enrollment_date: enrollmentDate
                }), 
            })
            .then((response) => {
                console.log("response download", response);
                if (!response.ok) {
                    alert("Already Exported");
                    throw new Error('Network response was not ok', response);
                }
                const filename = response.headers.get('X-File-Name') || 'exported-data.pdf';
                return response.blob().then(blob => ({ filename, blob }));
            })
            .then(({ filename, blob }) => {
                if (blob.size === 0) {
                    throw new Error('Received an empty PDF file');
                }
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => console.error('Error exporting to PDF:', error));
        };

    const ReportsScreen = () => {
        return (
            <>
             <
                WarningModal
                setShowWarningModal={setDeleteModal}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDelete}
                showModal={isDeleteModal}
              />
              <ToastContainer/>
                <main id="main" class="main">
                    <div className='container'>
                        <div className='card'>
                            <div className='card-body'>
                                {/* Total Collection: {total_amount} */}
                                <div className='row' style={{ margin: "20px" }}>
                                    <div className='col-md-3'>
                                        <select
                                            value={subAgentValue}
                                            className='form-control'
                                            onChange={(e) => setSubAgentValue(e.target.value)}
                                        >
                                            <option value="">Select SubAgent</option>
                                            {subAgents.map((eventData) => {
                                                console.log("eventData", eventData);
                                                return (
                                                    <option key={eventData._id} value={eventData._id}>{eventData.full_name}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-md-3'>
                                        <select
                                            value={examNameValue}
                                            className='form-control'
                                            onChange={(e) => setExamNameValue(e.target.value)}
                                        >
                                            <option value="">Select Exam</option>
                                            <option key="गुणवत्ता शोध परीक्षा" value="गुणवत्ता शोध परीक्षा">गुणवत्ता शोध परीक्षा</option>
                                            <option key="शताब्दी शिष्यवृत्ती परीक्षा" value="शताब्दी शिष्यवृत्ती परीक्षा">शताब्दी शिष्यवृत्ती परीक्षा</option>
                                        </select>
                                    </div>
                                    <div className='col-md-3'>
                                        <select
                                            value={examStandardValue}
                                            className='form-control'
                                            onChange={(e) => setExamStandardValue(e.target.value)}
                                        >
                                            <option value="">Select Standard</option>
                                            <option key="1" value="1">1</option>
                                            <option key="2" value="2">2</option>
                                            <option key="3" value="3">3</option>
                                            <option key="4" value="4">4</option>
                                            <option key="6" value="6">6</option>
                                            <option key="7" value="7">7</option>
                                        </select>
                                    </div>
                                    <div className='col-md-3'>
                                        <select
                                            value={schoolNameValue}
                                            className='form-control'
                                            onChange={(e) => setSchoolNameValue(e.target.value)}
                                        >
                                            <option value="">Select Schools</option>
                                            {schoolsAgentWise?.map((eventData) => {
                                                console.log("eventData", eventData);
                                                return (
                                                    <option key={eventData.school_name} value={eventData.school_name}>{eventData.school_name}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                   
                                </div>
                                <div className='row'  style={{ margin: "20px" }}>
                                    <div className='col-md-3'>
                                    <div className="form-group">
                                  
                                    <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="enrollmentDate"
                  placeholder='Enrollment Date'
                  value={enrollmentDate}
                  onChange={(e) => setEnrollmentDate(e.target.value)}
                   />
                    </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <button className='btn btn-primary' onClick={() => applyFilter()}>Apply Filter</button>
                                    </div>
                                    <div className='col-md-3'>
                                        <button className='btn btn-primary' 
                                        onClick={() => exportToExcel()}>
                                            Export To Excel
                                            </button>

                                            {/* <button className='btn btn-primary' 
                                        onClick={() => exportToPDF()}>
                                            Export To PDF
                                            </button> */}
                                    </div>
                                </div>
                                <table className="bordered-table">
                                    <thead>
                                        <tr>
                                        <th>Action</th>
                                            <th>Index</th>
                                            <th>Exam</th>
                                            <th>Student</th>
                                            <th>Standard</th>
                                            <th>Student Address</th>
                                            <th>School Address</th>
                                            <th>Enrollment Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {examForms.map((item, index) => (
                                            <tr key={index}>
                                                 <td>
                
              <MdDelete onClick={()=>handleDelete(item)} style={{cursor:'pointer'}}/>
              </td>
                                                <td>{sequenceStartIndex + index}</td>
                                                <td>
                                                    {item.exam_name + " | " + item.exam_fees + " Rs " + item.form_created_date + " " + item.form_created_time}
                                                </td>
                                                <td>
                                                    {item.first_name + " " + item.middle_name + " " + item.last_name}
                                                </td>
                                                <td>
                                                    {item.exam_standard}
                                                </td>
                                                {/* <td>
                                                    {item.student_address + " | " + item.student_district + " | " + item.student_taluka + " | " + item.mobile_number}
                                                </td> */}
                                                 <td>
                                                    {item.student_district + " | " + item.student_taluka + " | " + item.mobile_number}
                                                </td>
                                                <td>
                                                    {item.school_name + " | " + item.school_district + " | " + item.school_taluka + " | " + item.school_area}
                                                </td>
                                                <td>
                                                    {item.enrollment_date}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
      <button onClick={() => handleFirstPage()} disabled={page === 0}><MdSkipPrevious/></button> 

                                <button onClick={() => setPage(page - 1)} disabled={page === 0}><GrFormPrevious /></button>
                                <button onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}><MdOutlineNavigateNext /></button>
       <button onClick={() => handleLastPage()} disabled={page === totalPages - 1}><MdSkipNext/></button> 

                                <div>
                                    <select value={rowsPerPage} onChange={handlePageSizeChange}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    };

    return (
        <Template>{ReportsScreen()}</Template>
    );
}

export default Reports;