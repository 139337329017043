import React,{useState,useEffect} from 'react'
import Template from '../Components/Template'
import { useDeleteExamFormMutation, useGetAllExamFormsByAgentsQuery, useGetDistrictQuery, useGetTalukaByDistrictMutation, useSaveExamFormMutation } from '../Services/modules/exam';
import { Config } from '../Config';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useDataTable from '../Hooks/useDataTable';
import { MdOutlineNavigateNext, MdSkipNext, MdSkipPrevious, MdEdit, MdDelete } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import WarningModal from '../Components/WarningModal';
import { useSelector } from 'react-redux';

function Home() {
  const location = useLocation();
  const userData = useSelector((state) => state.userData.user_data);
//console.log("Usedata",userData)
  const [isSumbmiting,setIsSubmitting]=useState(false)
  const [isUpdate,setIsUpdate]=useState("")  
  const[exam_id,setExamId]=useState("")
  const[exam_name,setExamName]=useState("")
  const[enrollmentDate,setEnrollmentDate]=useState("")
  const[first_name,setFirstName]=useState('')
  const[middle_name,setMiddleName]=useState('')
  const[last_name,setLastName]=useState('')
const[mobile_number,setMobileNumber]=useState("")
const[student_address,setStudentAddress]=useState("")
  const[school_name,setSchoolName]=useState("")
  const[center_name,setCenterName]=useState("")
  const[school_contact_person,setSchoolContactPerson]=useState(userData.full_name)
const[school_area,setSchoolArea]=useState("")
  
  const[student_district_name,setStudentDistrictName]=useState('')
  const[student_taluka,setStudentTaluka]=useState('')
  const[student_district_id,setStudentDistrictId]=useState("")
  const[exam_standard,setExamStandard]=useState("")

  const[school_district_id,setSchoolDistrictId]=useState("")
  const[school_district_name,setSchoolDistrictName]=useState('')

  const[school_taluka,setSchoolTaluka]=useState('')

  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const[allDistrictsStudents,setAllDistrictsStudents]=useState([])
  const[allTalukasStudents,setAllTalukasStudents]=useState([])
  const[allTalukasSchool,setAllTalukasSchool]=useState([])
 const[examForms,setExamForms]=useState([])
const[form_id,setFormId]=useState("")
 const[isDelete,setIsDelete]=useState(false) 
    const[isDeleteModal,setDeleteModal]=useState(false)
const[gender,setGender]=useState("")
  const { data: districts } = useGetDistrictQuery();
  const {   
    handleFirstPage,
    handlePageSizeChange,
    handleLastPage,
    sequenceStartIndex,
    page,
    rowsPerPage,
    totalRows,
    setTotalRows,
    setPage,
    setTotalPages,
    totalPages
} = useDataTable();
  const { data, isSuccess, isLoading, error, refetch } = useGetAllExamFormsByAgentsQuery({
    page_number: page,
    page_size: rowsPerPage,
    subAgentValue: "",
    examNameValue: "",
    examStandardValue: ""
});
useEffect(() => {
  if (isSuccess) {
     // console.log("Exam form data by agents", data);
      setExamForms(data.data);
  }
}, [data, isSuccess]);
  const [SaveExamForm, { data:saveData, isSuccess:saveSuccess, isLoading:saveLoading, isError:saveIsError, error:saveError }] = useSaveExamFormMutation();
  
  const [DeleteExamForm, { 
          data:deleteData=[], 
          isSuccess:deleteIsSuccess, 
          isLoading:deleteIsLoading, 
          isError:deleteIsError, 
          error:deleteError }] = useDeleteExamFormMutation();
  const sampleData = [
    "Springfield Elementary",
    "Shelbyville High",
    "South Park Elementary",
    "Sunnydale High",
    "Hogwarts School of Witchcraft and Wizardry",
    "Shermer High School",
    "Rydell High School",
    "West Beverly High School",
    "Bayside High School",
    "Hill Valley High School"
  ];
  const handleSearch = () => {
    // const filteredSuggestions = sampleData.filter(school =>
    //   school.toLowerCase().includes(schoolName.toLowerCase())
    // );
    fetch(`${Config.API_URL_LOCAL}exam/searchExamSchools`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ keyword: school_name }), // Correctly format the body
  })
  .then((response) => {
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      console.log("responsesearch",response)
     // setSuggestions(response.allSchools);
     // setShowSuggestions(true);
      return response.json(); // Parse the JSON response
  }).then((data) => {
    console.log("response schools", data);
    setSuggestions(data.allSchools)  
    setShowSuggestions(true);      
})
    // setSuggestions(filteredSuggestions);
    // setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    setSchoolName(suggestion);
    setShowSuggestions(false);
  };

  const getTalukaByDistrict = (selectedDistrict) => {
    fetch(`${Config.API_URL_LOCAL}exam/gettalukabydistrict`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ district_id: selectedDistrict }), // Correctly format the body
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the JSON response
    })
    .then((data) => {
        console.log("response talukas", data);
        setAllTalukasStudents(data.allTalukas)        
    })
    .catch((error) => {
        console.error('Error fetching talukas:', error);
    });
};

const getTalukaByDistrictSchool = (selectedDistrict) => {
  fetch(`${Config.API_URL_LOCAL}exam/gettalukabydistrict`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ district_id: selectedDistrict }), // Correctly format the body
  })
  .then((response) => {
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the JSON response
  })
  .then((data) => {
      console.log("response talukas", data);
      setAllTalukasSchool(data.allTalukas)        
  })
  .catch((error) => {
      console.error('Error fetching talukas:', error);
  });
};
useEffect(()=>{
  const queryParams = new URLSearchParams(location.search);
  const examParam = queryParams.get('exam');
  // setExamName(examParam)
  // if(examParam=="tse")
  //   {
  //     setExamId("646cab9bde9bdd1eb74a41e8")
  //   }
  //   if(examParam=="shatabdi")
  //     {
  //       setExamId("646cabb1de9bdd1eb74a41ea")
  //     }
  // console.log("examParam",examParam)
},[])

  useEffect(()=>{
    setAllDistrictsStudents(districts?.allDistricts)
   // console.log("districts",districts)
  },[districts])
  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    const selectedDistrictName = e.target.options[e.target.selectedIndex].text;
    console.log("selectedDistrictName",selectedDistrictName)
    setStudentDistrictId(selectedDistrict)
    setStudentDistrictName(selectedDistrictName);
    if (selectedDistrict) {
      getTalukaByDistrict(selectedDistrict);
    }
  };

  const handleDistrictChangeSchool = (e) => {
    const selectedDistrict = e.target.value;
    const selectedDistrictName = e.target.options[e.target.selectedIndex].text;
    console.log("selectedDistrictName",selectedDistrictName)
    setSchoolDistrictId(selectedDistrict)
    setSchoolDistrictName(selectedDistrictName);
    if (selectedDistrict) {
      getTalukaByDistrictSchool(selectedDistrict);
    }
  };
  const onSubmit=()=>{
    setIsSubmitting(true)
    console.log("Gender on submit",gender)
    if(!isUpdate)
    {
      SaveExamForm(
        {
          exam_id,
          enrollment_date:enrollmentDate,
          first_name:first_name,
          middle_name,
          last_name,
          mobile_number,
          student_district:student_district_name,
          student_taluka:student_taluka,
          student_address,
          exam_standard,
          school_name,
          center_name,
          school_district:school_district_name,
          school_area,
          school_taluka,
          school_contact_person,
          gender:gender

      }
    )

    }
    if(isUpdate)
    {
      SaveExamForm(
        {
          exam_id,
          enrollment_date:enrollmentDate,
          first_name:first_name,
          middle_name,
          last_name,
          mobile_number,
          student_district:student_district_name,
          student_taluka:student_taluka,
          student_address,
          exam_standard,
          school_name,
          center_name,
          school_district:school_district_name,
          school_area,
          school_taluka,
          school_contact_person,
          gender:gender
      }
      )

    }
  }
  const clearExamForm=()=>{
    setFirstName("")
    setMiddleName('')
    setLastName('')
    setCenterName("")
    setStudentAddress('')
    setMobileNumber("")
    // setSchoolContactPerson("")
  }
  useEffect(()=>{
    console.log("saveData",saveData)
    if(saveSuccess)
      {
        setIsSubmitting(false)
        clearExamForm()
        toast.success("form saved successfully")
      }
  },[saveData])
  const handleEdit=(dataRow)=>{
    setFirstName(dataRow.first_name)
    setMiddleName(dataRow.middle_name)
    setLastName(dataRow.last_name)
    setCenterName(dataRow.center_name)
    setStudentAddress(dataRow.student_address)
    setMobileNumber(dataRow.mobile_number)
    setSchoolName(dataRow.school_name)

    setSchoolContactPerson(dataRow.contact_person_name)
  }
  const handleDelete=(dataRow)=>{
    setDeleteModal(true)
    setFormId(dataRow._id)
  }
  useEffect(() => {
    if(isDelete)
    {
      DeleteExamForm({form_id})
    }
  
  }, [isDelete]);
  useEffect(()=>{

    if(deleteIsSuccess)
    {
      console.log("deleteddataProduct",deleteData)

      if(!deleteData.error)
      {
        // setProducts(deleteData.data)
        // setTotalRows(deleteData.totalRows)
        toast.success(deleteData.message)
        setIsDelete(false)
        setDeleteModal(false)


//refetch()
        
      }
      else
      {
        toast.error(deleteData.message)
        setIsDelete(false)
        setDeleteModal(false)

      }
    
    }
      },[deleteData])

      useEffect(() => {
        refetch();
      }, [page]);
    const HomeScreen=()=>{
      return(
        <>
         <
                WarningModal
                setShowWarningModal={setDeleteModal}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDelete}
                showModal={isDeleteModal}
              />
        <ToastContainer/>
        <main id="main" class="main">
<div className='container'>
  <div className="row">
  <div className='col-md-4'>
    <div className='card'>
      <div className="card-body">
      <div className="form-group">
      <label htmlFor="input1">परीक्षा <span className="red-star">*</span></label>
                    <select
                                            value={exam_id}
                                            className="form-control mb-3" 
                                            onChange={(e) => setExamId(e.target.value)}
                                           //onChange={handleDistrictChange}
                                        >
                                            <option value="">Select Exam</option>
                                            
                              <option value="646cab9bde9bdd1eb74a41e8">गुणवत्ता शोध परीक्षा</option>
                              <option value="646cabb1de9bdd1eb74a41ea">शताब्दी शिष्यवृत्ती परीक्षा</option>
                                
                                        </select>
                                        </div>
      <div className="form-group">
                  <label htmlFor="input1">Enrollment Date <span className="red-star">*</span></label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="enrollmentDate"
                  value={enrollmentDate}
                  onChange={(e) => setEnrollmentDate(e.target.value)}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input1">इयत्ता <span className="red-star">*</span></label>
                    <select
                                            value={exam_standard}
                                            className="form-control mb-3" 
                                            onChange={(e) => setExamStandard(e.target.value)}
                                           //onChange={handleDistrictChange}
                                        >
                                            <option value="">Select Standard</option>
                                            {
                          exam_id === "646cab9bde9bdd1eb74a41e8" && (
                            <>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="6">6</option>
                            </>
                          )
                        }
                         {
                          exam_id === "646cabb1de9bdd1eb74a41ea" && (
                            <>
                              <option value="4">4</option>
                              <option value="7">7</option>
                             
                            </>
                          )
                        }
                                           
                                        </select>
                                        </div>

                                        <div className="form-group">
                  <label htmlFor="input1">नाव <span className="red-star">*</span></label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="first_name"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input1">वडिलांचे नाव <span className="red-star">*</span></label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="middle_name"
                  value={middle_name}
                  onChange={(e) => setMiddleName(e.target.value)}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input1">आडनाव <span className="red-star">*</span></label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="last_name"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                   />
                </div>
                <div className="form-group">
      <label htmlFor="input1">लिंग <span className="red-star">*</span></label>
                    <select
                                            value={gender}
                                            className="form-control mb-3" 
                                            onChange={(e) => setGender(e.target.value)}
                                           //onChange={handleDistrictChange}
                                        >
                                            <option value="">Select Gender</option>
                                            
                              <option value="male">पुरुष</option>
                              <option value="female">स्त्री</option>
                                
                                        </select>
                                        </div>
                <div className="form-group">
                  <label>विद्यार्थ्यांचा जिल्हा <span className="red-star">*</span></label>
                    <select
                                            value={student_district_id}
                                            className="form-control mb-3" 
                                           // onChange={(e) => setStudentDistrict(e.target.value)}
                                           onChange={handleDistrictChange}
                                        >
                                            <option value="">Select District</option>
                                            {allDistrictsStudents?.map((eventData) => {
                                                //console.log("eventData", eventData);
                                                return (
                                                    <option key={eventData._id} value={eventData._id}>{eventData.district_name}</option>
                                                );
                                            })}
                                        </select>
                                        </div>
                                        <div className="form-group">
                                          <label>विद्यार्थ्यांचा तालुका <span className="red-star">*</span></label>
                    <select
                                            value={student_taluka}
                                            className="form-control mb-3" 
                                            onChange={(e) => setStudentTaluka(e.target.value)}
                                           
                                        >
                                            <option value="">Select Taluka</option>
                                            {allTalukasStudents?.map((eventData) => {
                                              //  console.log("eventData", eventData);
                                                return (
                                                    <option key={eventData._id} value={eventData.taluka_name}>{eventData.taluka_name}</option>
                                                );
                                            })}
                                        </select>
                                        </div>
                                        {/* <div className="form-group">
                  <label htmlFor="input1">कायमस्वरूपी निवासी पत्ता <span className="red-star">*</span></label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="student_address"
                  value={student_address}
                  onChange={(e) => setStudentAddress(e.target.value)}
                   />
                </div> */}
                <div className="form-group">
                  <label htmlFor="input1">दूरध्वनी क्रमांक <span className="red-star">*</span></label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="mobile_number"
                  value={mobile_number}
                  onChange={(e) => setMobileNumber(e.target.value)}
                   />
                </div>

                <div className="form-group">
                  <label>शाळा क्षेत्र</label>
                    <select
                                            value={school_area}
                                            className="form-control mb-3" 
                                            onChange={(e) => setSchoolArea(e.target.value)}
                                           //onChange={handleDistrictChange}
                                        >
                                            <option value="">शाळा क्षेत्र <span className="red-star">*</span></option>
                                           
                              <option value="शहरी">शहरी</option>
                              <option value="ग्रामीण">ग्रामीण</option>
                             
                                           
                                        </select>
                                        </div>
                                        <div className="form-group">
                                        <label htmlFor="input1">शाळेचा जिल्हा <span className="red-star">*</span></label>
                    <select
                                            value={school_district_id}
                                            className="form-control mb-3" 
                                           // onChange={(e) => setStudentDistrict(e.target.value)}
                                           onChange={handleDistrictChangeSchool}
                                        >
                                            <option value="">Select District</option>
                                            {allDistrictsStudents?.map((eventData) => {
                                               // console.log("eventData", eventData);
                                                return (
                                                    <option key={eventData._id} value={eventData._id}>{eventData.district_name}</option>
                                                );
                                            })}
                                        </select>
                                        </div>
                                        <div className="form-group">
                                          <label>शाळेचा तालुका <span className="red-star">*</span></label>
                    <select
                                            value={school_taluka}
                                            className="form-control mb-3" 
                                            onChange={(e) => setSchoolTaluka(e.target.value)}
                                           
                                        >
                                            <option value="">Select Taluka</option>
                                            {allTalukasSchool?.map((eventData) => {
                                              //  console.log("eventData", eventData);
                                                return (
                                                    <option key={eventData._id} value={eventData.taluka_name}>{eventData.taluka_name}</option>
                                                );
                                            })}
                                        </select>
                                        </div>
                <div className="form-group">
                      <label htmlFor="input2">शाळेचे नाव<span className="red-star">*</span></label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="input2"
                        name="school_name"
                        value={school_name}
                        onChange={(e) => setSchoolName(e.target.value)}
                      />
                      <button className="btn btn-warning mb-2" onClick={handleSearch}>Search</button>
                      {showSuggestions && (
                        <ul className="list-group mt-1">
                          {suggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              className="list-group-item list-group-item-action"
                              onClick={() => handleSuggestionClick(suggestion.school_name)}
                            >
                              {suggestion.school_name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  
                    <div className="form-group">
                  <label htmlFor="input1">केंद्राचे नाव</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="student_address"
                  value={center_name}
                  onChange={(e) => setCenterName(e.target.value)}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input1">शाळेतील संपर्ककर्त्याचे नाव</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="student_address"
                  value={school_contact_person}
                  onChange={(e) => setSchoolContactPerson(e.target.value)}
                   />
                </div>
                <div className='form-group'>
                <button type="submit" 
                className="btn btn-primary"              
                onClick={()=>onSubmit()}
                >
                   {isSumbmiting ? 
                  'Submitting...' : 
                  isUpdate?'Update':
                  'Submit'} 
                 
                </button>

                </div>


                                      

                                       

      </div>
    </div>
  </div>
  <div className='col-md-8'>
    <div className='card'>
      <div className="card-body">
      <table className="bordered-table">
                                    <thead>
                                        <tr>
                                          <th>Action</th>
                                            <th>Index</th>
                                            <th>Exam</th>
                                            <th>Student</th>
                                            <th>Standard</th>
                                            <th>Student Address</th>
                                            <th>School Address</th>
                                            <th>Enrollment Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {examForms.map((item, index) => (
                                            <tr key={index}>
                                              <td>
                <MdEdit onClick={()=>handleEdit(item)} 
                style={{cursor:'pointer'}}/> 
              <MdDelete onClick={()=>handleDelete(item)} style={{cursor:'pointer'}}/>
              </td>
                                                <td>{sequenceStartIndex + index}</td>
                                                <td>
                                                    {item.exam_name + " | " + item.exam_fees + " Rs " + item.form_created_date + " " + item.form_created_time}
                                                </td>
                                                <td>
                                                    {item.first_name + " " + item.middle_name + " " + item.last_name}
                                                </td>
                                                <td>
                                                    {item.exam_standard}
                                                </td>
                                                <td>
                                                    {item.student_address + " | " + item.student_district + " | " + item.student_taluka + " | " + item.mobile_number}
                                                </td>
                                                <td>
                                                    {item.school_name + " | " + item.school_district + " | " + item.school_taluka + " | " + item.school_area}
                                                </td>
                                                <td>
                                                    {item.enrollment_date}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <button onClick={() => setPage(page - 1)} disabled={page === 0}><GrFormPrevious /></button>
                                <button onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}><MdOutlineNavigateNext /></button>
                                <div>
                                    <select value={rowsPerPage} onChange={handlePageSizeChange}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
      </div>
    </div>
  </div>
  </div>

</div>
        </main>
        </>
      )
      
    }
  return (
    <Template>
        {HomeScreen()}
    </Template>
  )
}

export default Home