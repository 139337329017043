export default build =>
    build.mutation({
        query(payload) {         
            return {
                url: '/auth/signup',
                method: 'POST',
                body: payload,
            };
        }
       
    })
