import { api } from '../../api'
import addEventName from './addEventName'
import addEventYear from './addEventYear'
import deleteEventName from './deleteEventName'
import deleteEventPhoto from './deleteEventPhoto'
import deleteEventYear from './deleteEventYear'
import getAllEventsByYear from './getAllEventsByYear'
import getAllYears from './getAllYears'
import getEventPhotos from './getEventPhotos'
import updateEventName from './updateEventName'


export const photoGalleryApi = api.injectEndpoints({
    endpoints: build => ({
       
        AddEventYear:addEventYear(build),
        DeleteEventYear:deleteEventYear(build),
        GetAllYears:getAllYears(build),
        AddEventName:addEventName(build),
        UpdateEventName:updateEventName(build),
        DeleteEventName:deleteEventName(build),
        GetAllEventsByYear:getAllEventsByYear(build),
        GetEventPhotos:getEventPhotos(build),
        DeleteEventPhoto:deleteEventPhoto(build)
      
    }),
    overrideExisting: true,
})

export const { 
   useAddEventYearMutation,
   useDeleteEventYearMutation,
   useGetAllYearsQuery,
   useAddEventNameMutation,
   useUpdateEventNameMutation,
   useDeleteEventNameMutation,
   useGetAllEventsByYearQuery,
   useGetEventPhotosQuery,
   useDeleteEventPhotoMutation


 } = photoGalleryApi

