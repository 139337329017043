import { api } from '../../api'
import calculateTotalAmountAgent from './calculateTotalAmountAgent'
import deleteExamForm from './deleteExamForm'
import getAllExamForms from './getAllExamForms'

import getAllExamFormsByAgent from './getAllExamFormsByAgent'

import getExamFormsByAgentFilter from './getExamFormsByAgentFilter'

import getDistricts from './getDistricts'
import getSubAgents from './getSubAgents'
import getTaluka from './getTaluka'
import saveExamForm from './saveExamForm'
import getSchoolsAgentWise from './getSchoolsAgentWise'
import getAllExamFormsByAgentAdminFilter from './getAllExamFormsByAgentAdminFilter'

export const examApi = api.injectEndpoints({
    endpoints: build => ({
     
      GetAllExamFormsByAgents: getAllExamFormsByAgent(build),
      GetAllExamFormsByAgentsFilter: getExamFormsByAgentFilter(build),
      GetAllExamFormsByAgentsAdminFilter:getAllExamFormsByAgentAdminFilter(build),

        GetAllExamForms:getAllExamForms(build),
        GetSubAgentsByAgent:getSubAgents(build),
        GetDistrict:getDistricts(build),
        GetTalukaByDistrict:getTaluka(build),
        SaveExamForm:saveExamForm(build),
        DeleteExamForm:deleteExamForm(build),
        GetTotalAmountAgent:calculateTotalAmountAgent(build),
        GetSchoolsAgentWise:getSchoolsAgentWise(build)
       
    }),
    overrideExisting: true,
})

export const { 
    useGetAllExamFormsByAgentsQuery,

   useGetAllExamFormsByAgentsFilterMutation,
   useGetAllExamFormsByAgentsAdminFilterMutation,
    useGetAllExamFormsQuery,
    useGetSubAgentsByAgentQuery,
    useGetDistrictQuery,
    useGetTalukaByDistrictMutation,
    useSaveExamFormMutation,
    useDeleteExamFormMutation,
    useGetTotalAmountAgentQuery,
    useGetSchoolsAgentWiseQuery
    
 } = examApi

