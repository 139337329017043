export default build =>
    build.query({
        tagTypes: ['schoolsAgentWise'],
        keepUnusedDataFor: 1,
        query(payload) {
            //console.log("payload",payload)            
            return {
                url: `/exam/getSchoolsFillupByAgents`,
                method: 'GET',
               // body: payload,
            };
           
        },
       providesTags: ['schoolsAgentWise']
    })


