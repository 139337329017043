export default build =>
    build.mutation({
        //tagTypes: ['allExamFormsByAgents'],
       // keepUnusedDataFor: 1,
        query(payload) {
            //console.log("payload",payload)            
            return {
                //url: `/exam/getAllExamFormsByAgent?page_number=${payload.page_number}&fillupby_id=${payload.subAgentValue}&exam_name=${payload.examNameValue}&exam_standard=${payload.examStandardValue}&page_size=${payload.page_size}`,                
                url: `/exam/getAllExamFormsByAgentFilter`,                

                method: 'POST',
                body: payload,
            };
          
        },
       //providesTags: ['allExamFormsByAgents']
    })
