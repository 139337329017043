export default build =>
    build.mutation({      
        query(payload) {                   
            return {   
                url: `/exam/getAllExamFormsByAgentAdminFilter?page_number=${payload.page_number}&agent_id=${payload.agent_id}&page_size=${payload.page_size}`,                             
                method: 'POST',
                body: payload,
            };
          
        },
      
    })


