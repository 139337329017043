import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLoginMutation } from '../Services/modules/auth';
import { updateUserData } from '../Store/UserData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, useNavigate } from 'react-router-dom';

function Login() {
  const isLoggedin = useSelector(state => state.userData.user_data == null ? false : true);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    mobile_number: "",
    password: ""
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const[isSumbmiting,setIsSubmiting]=useState(false)
  const [Login, { data, isSuccess, isLoading, isError, error }] = useLoginMutation();
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccess) {
      console.log("InSuccess", data);

      if (data.error === true) {
        toast.error(data.message);
        setIsSubmiting(false)
        return;
      }
      dispatch(updateUserData({ data: data }));
    }
    if (isError) {
      setIsSubmiting(false)

      toast.error("In errorSomething went wrong");
      console.log(error);

    }
  }, [isLoading]);

  function onSubmit() {
    setIsSubmiting(true)
    setTimeout(()=>{
    Login(state);

    },1000)
  }

  const validateForm = () => {
    const { email, password } = state;
    if (email !== '' && password !== '') {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
    validateForm();
  }
  const handleKeypress = e => {
    
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  const goToSignup=()=>{
    navigate("/signup")
       }
  return (
    isLoggedin ? <Navigate to="/dashboard" /> :
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="container">
          <ToastContainer/>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="white-box p-4">
                <h2 className="text-center">Login</h2>
                <div className="form-group">
                  <label htmlFor="username" className="mb-2">Username</label> {/* Added margin-bottom */}
                  <input
                    type="text"
                    className="form-control"
                    id="mobile_number"
                    placeholder="Enter your mobile_number"
                    value={state.mobile_number}
                    name="mobile_number"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="mb-2">Password</label> {/* Added margin-bottom */}
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={state.password}
                    onChange={handleChange}
                    onKeyDown={handleKeypress}

                    placeholder="Enter your password"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                  onClick={() => onSubmit()}
                  disabled={!isFormValid || isLoading || isSumbmiting} 
                >
                  {isLoading || isSumbmiting ? 'Submitting...' : 'Submit'} 
                </button>
                <a onClick={()=>goToSignup()} style={{cursor:'pointer'}}> 
              
              {/* <div class="section-title" >
      <h4 style={{marginTop:'20px',fontSize:'20px'}}>
       <span style={{color:'#4c70ff'}}>Don't have an account?</span>  
       <span style={{color:'#db5eff'}}> Signup</span>
        </h4>
        </div> */}
              </a> 
              </div>
             
            </div>
          </div>
        </div>
      </div>
  );
}

export default Login;