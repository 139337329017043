import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'productData',
    initialState: { product_data: null },
    reducers: {
        updateProductData: (state, { payload: { data } }) => {
            state.product_data = data
        },
        clearProductData: (state, { }) => {
            state.product_data = null
        },
    },
})

export const { updateProductData, clearProductData } = slice.actions

export default slice.reducer
