export default build =>
    build.query({
        tagTypes: ['productbyid'],
        keepUnusedDataFor: 1,
        query(payload) {
           
            console.log("in get gallery by id",payload)
            return {
                url: `/product/getProductById?product_id=${payload.product_id}`,           
                method: 'GET',
              //  body: payload,
               
            };
        },
        providesTags: ['productbyid'],
    })
