export default build =>
    build.query({
        tagTypes: ['totalAmountAgent'],
        keepUnusedDataFor: 1,
        query(payload) {
            //console.log("payload",payload)            
            return {
                url: `/exam/calculateTotalAmountAgent?page_number=${payload.page_number}&fillupby_id=${payload.subAgentValue}&exam_name=${payload.examNameValue}&exam_standard=${payload.examStandardValue}&page_size=${payload.page_size}`,                
                method: 'GET',
               // body: payload,
            };
          
        },
       providesTags: ['totalAmountAgent']
    })
