export default build =>
    build.query({
        tagTypes: ['eventByYear'],
        keepUnusedDataFor: 1,
        query(payload) {
           
            console.log("in get gallery by id",payload)
            return {
                url: `/photogallery/getAllEventsByYear?year_id=${payload.year_id}&page_number=${payload.page_number}&page_size=${payload.page_size}`,           
                method: 'GET',
              //  body: payload,
               
            };
        },
        providesTags: ['eventByYear'],
    })
