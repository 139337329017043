export default build =>
    build.mutation({
        query(payload) {
            return {
                url: `/exam/paymentorder`,
                method: 'POST',
                body: payload
            };
        },
        invalidatesTags: ['allExamFormsByAgents'],

    })
