import { useState, useEffect } from 'react';

const useDataTable=()=>{

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set an initial value
    const[totalRows,setTotalRows]=useState()
    const [totalPages, setTotalPages] = useState(0);
    const [sequenceStartIndex, setSequenceStartIndex] = useState(0);
    useEffect(() => {
        if (totalRows > 0 && rowsPerPage > 0) {
          const pages = Math.ceil(totalRows / rowsPerPage);
          setTotalPages(pages);
        }
      }, [totalRows, rowsPerPage]);
    
      useEffect(() => {
        setSequenceStartIndex(page * rowsPerPage + 1); // Calculate the start index of the current page sequence when the page changes
      }, [page, rowsPerPage]);
      const handleLastPage = () => {
        setPage(totalPages -1);
        //setPage(page + 1);

      };
      const handleFirstPage=()=>{
        console.log("In handle first page")
        setPage(0); // Set page index to 0 to navigate to the first page
      }
      const handlePageSizeChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0); // Reset to the first page when page size changes
      };

      return {   
        handleFirstPage ,
        handlePageSizeChange,
        handleLastPage,
        sequenceStartIndex  ,
        page,
        rowsPerPage,
        totalRows,
        setTotalRows,
        setPage,
        setTotalPages   ,
        totalPages 
        // Add other state variables and functions here
      };
}

export default useDataTable;
